<template>
  <div class="view-container">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
