<template>
  <div class="top-nav" ref="nav">
    <ViewContainer class="nav-main" :class="{ 'is-fixed': fixed }">
      <ViewWrapper
        class="nav-list"
        ref="navList"
        :style="{ width: list.length > 4 ? '1400px' : '' }"
      >
        <a
          href=""
          ref="navItem"
          class="nav-item"
          :class="{ 'is-active': idx === actived }"
          v-for="(item, idx) in list"
          :key="idx"
          @click.prevent="handleClick(item, idx)"
          >{{ item.name }}</a
        >
      </ViewWrapper>
    </ViewContainer>
  </div>
</template>

<script>
import Vue from 'vue'
import anime from 'animejs'
import ViewContainer from '../ViewContainer'
import ViewWrapper from '../ViewWrapper'

export default {
  components: {
    ViewContainer,
    ViewWrapper,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      fixed: false,
      actived: 0,
      disabled: false,
    }
  },
  mounted () {
    this.initScroll()
    window.addEventListener('scroll', this.initScroll)
    this.initHashScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.initScroll)
  },
  methods: {
    initScroll () {
      this.fixedNav()
      this.activedNav()
    },
    // 初始化导航根据hash设置锚点位置
    initHashScroll () {
      const hash = window.location.hash
      if (hash) {
        const idx = this.list.indexOf(
          this.list.find(item => item.hash === hash.substring(1)),
        )
        setTimeout(() => {
          // this.scrollNav(idx)
          this.handleClick(this.list[idx], idx)
        }, 300)
      }
    },
    fixedNav () {
      const headerHegiht = document.querySelector('#app>.header_container')
        .clientHeight
      const sTop = document.body.scrollTop || document.documentElement.scrollTop
      const navTop = this.$refs.nav.offsetTop
      if (sTop >= navTop - headerHegiht) {
        Vue.$gNavigation.hide()
      } else {
        Vue.$gNavigation.show()
      }
      this.fixed = sTop >= navTop
    },
    activedNav () {
      if (this.disabled) return
      const { availHeight } = window.screen
      const sTop = document.body.scrollTop || document.documentElement.scrollTop

      this.list.forEach(({ hash }, idx) => {
        const { offsetTop, clientHeight } = document.getElementById(hash)
        const viewAvail = availHeight / 2
        if (
          sTop > offsetTop - viewAvail &&
          sTop < offsetTop + clientHeight - viewAvail
        ) {
          this.actived = idx
          // this.scrollNav(idx)
        }
      })
    },
    scrollNav (idx) {
      const { offsetLeft } = this.$refs.navItem[idx]
      this.$refs.navList.scrollLeft = offsetLeft
    },
    handleClick ({ hash }, idx) {
      if (this.disabled) return
      this.actived = idx
      const $el = document.getElementById(hash)
      const oldTop =
        document.body.scrollTop || document.documentElement.scrollTop
      const animeObj = {
        scrollTop: oldTop,
      }
      // const headerHegiht = document.querySelector('#app>.nav_wrapper')
      //   .clientHeight
      const navHegiht = this.$refs.nav.clientHeight
      // const newTop = $el.offsetTop - headerHegiht - navHegiht
      const newTop = $el.offsetTop - navHegiht
      this.disabled = true
      anime({
        targets: animeObj,
        scrollTop: [oldTop, newTop],
        easing: 'easeInOutExpo',
        duration: 800,
        update: () => {
          document.body.scrollTop = document.documentElement.scrollTop =
            animeObj.scrollTop
        },
        complete: () => {
          this.disabled = false
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
